export default [
    {
        path: '/blog/academico/doctorado/universidades-con-doctorado-en-mexico-opciones',
        name: 'Descubre las mejores opciones de universidades con doctorado en México',
        meta: {
          title: "Descubre las mejores opciones de universidades con doctorado en México",
          description: "Explora estas alternativas de las mejores universidades para estudiar un doctorado en México.",
          image: "https://humanitas.edu.mx/images/meta/doctorado-humanitas-para-alcanzar-tus-metas.webp",
          requireAuth: false,
          metaTags:
            {
              "og:title": "Descubre las mejores opciones de universidades con doctorado en México",
              "og:description": "Explora estas alternativas de las mejores universidades para estudiar un doctorado en México.",
              "og:image": "https://humanitas.edu.mx/images/meta/doctorado-humanitas-para-alcanzar-tus-metas.webp",
            },
        },
        component: () => import('../../pages/Blog/Academico/Doctorado/0047-ART-ACADEMICO-DOCTORADO-240624.vue')
    },
    {
        path: '/blog/academico/doctorado/doctorados-virtuales-en-mexico',
        name: 'Doctorados virtuales en México: especialización y conocimiento',
        meta: {
          title: "Doctorados virtuales en México: especialización y conocimiento",
          description: "Los doctorados virtuales en México representan la culminación de los estudios universitarios que contemplan investigación y conocimiento profundo sobre un tema.",
          image: "https://humanitas.edu.mx/images/meta/doctorados-virtuales-mexico-estudiantes.jpg",
          requireAuth: false,
          metaTags:
            {
              "og:title": "Doctorados virtuales en México: especialización y conocimiento",
              "og:description": "Los doctorados virtuales en México representan la culminación de los estudios universitarios que contemplan investigación y conocimiento profundo sobre un tema.",
              "og:image": "https://humanitas.edu.mx/images/meta/doctorados-virtuales-mexico-estudiantes.jpg",
            },
        },
        component: () => import('../../pages/Blog/Academico/Doctorado/0045-ART-ACADEMICO-DOCTORADO-180624.vue')
    },
    {
        path: '/blog/academico/doctorado/guia-todo-sobre-doctorado-perfil-y-competencias',
        name: 'Si quieres hacer el doctorado, esta guía te ayudará, te decimos todo lo que necesitas',
        meta: {
          title: "Si quieres hacer el doctorado, esta guía te ayudará, te decimos todo lo que necesitas",
          description: "Descubre todo sobre el doctorado: las cualidades del estudiante ideal, estrategias para destacar y las competencias que adquirirás para tu vida. Lee esta guía.",
          image: "https://humanitas.edu.mx/images/meta/Todo-sobre-el-doctorado-guia.jpg",
          requireAuth: false,
          metaTags:
            {
              "og:title": "Si quieres hacer el doctorado, esta guía te ayudará, te decimos todo lo que necesitas",
              "og:description": "Descubre todo sobre el doctorado: las cualidades del estudiante ideal, estrategias para destacar y las competencias que adquirirás para tu vida. Lee esta guía.",
              "og:image": "https://blog.humanitas.edu.mx/images/meta/Todo-sobre-el-doctorado-guia.jpg",
            },
        },
        component: () => import('../../pages/Blog/Academico/Doctorado/06-ART-ACADEMICO-DOCTORADO-140224.vue')
    },
]